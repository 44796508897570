import { ReactComponent as Logomarca } from '../assets/logomarca.svg';
import { ReactComponent as Botao } from '../assets/botao.svg';
import { useState } from 'react';

function Cabecalho(props) {
    var [estado, setEstado] = useState(false);

    const [carregado, setCarregado] = useState(false);

    const AtualizaCSS = () => {
        document.getElementById("area-menu-dropdown").style.transition = "0.5s";
        document.getElementById("area-menu-dropdown").style.pointerEvents = "none";
        document.getElementById("area-menu-dropdown").style.height = "0px";
        document.getElementById("area-menu-dropdown").style.opacity = 0;
        document.getElementById("area-menu-dropdown").style.padding = 0;
        document.getElementById("cabecalho").style.transition = "1s";
        document.getElementById("cabecalho").style.borderRadius = "0 45px 45px 0";
    }

    const mudaEstado = () => {
        setEstado(!estado);
        if (estado == false) {
            document.getElementById("area-menu-dropdown").style.transition = "0.5s";
            document.getElementById("area-menu-dropdown").style.pointerEvents = "all";
            document.getElementById("area-menu-dropdown").style.height = "auto";
            document.getElementById("area-menu-dropdown").style.opacity = 1;
            document.getElementById("area-menu-dropdown").style.padding = "2vh";
            document.getElementById("cabecalho").style.transition = "2s";
            document.getElementById("cabecalho").style.borderRadius = "0 0 90px 0";
        } else {
            AtualizaCSS();
        }

    }

    const MenuCabecalho = () => {

        return (
            <ul>
                <li><a href="#o-que-fazemos">O que fazemos</a></li>
                <li><a href="#nossa-historia">Nossa história</a></li>
                <li><a href="#area-servicos">Serviços</a></li>
            </ul>
        )
    }

    const MenuDropdown = () => {
        return (
            <div id="area-menu-dropdown">
                {MenuCabecalho()}
            </div>
        );
    }

    const CabecalhoAbertoFechado = () => {
        if (props.dimensoes.largura >= 640) {

            return (
                <div id="cabecalho">
                    <div className="inline-flex">
                        <div id="area-logomarca-cabecalho">
                            <Logomarca id="logomarca-cabecalho" />
                        </div>
                        {MenuCabecalho()}
                    </div>
                </div>
            );
        } else {
            return (
                <div id="cabecalho">
                    <div className="inline-flex">
                        <div id="area-logomarca-cabecalho">
                            <Logomarca id="logomarca-cabecalho" />
                        </div>
                        <div id="area-botao-cabecalho">
                            <Botao onClick={mudaEstado} id="botao-cabecalho" />
                        </div>
                    </div>
                    {MenuDropdown()}
                </div>
            );
        }
    };

    return (
        <div id="area-cabecalho">
            {CabecalhoAbertoFechado()}
        </div>
    );
}

export default Cabecalho;