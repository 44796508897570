import yomo from "../assets/yomo_background.png";
import servicenow from "../assets/servicenow_background.png";
function Servicos() {
    return (
        <div className="container">
            <div id="area-servicos">
                <a href="https://yomo.com.br/" target="_blank" id="yomo">
                    <img src={yomo} id="yomo-imagem" alt='Yomo App'/>
                </a>
                <div id="servicenow">
                    <img src={servicenow} id="servicenow-imagem" alt='Consultoria em ServiceNow'/>
                </div>
            </div>
        </div>
    );
}

export default Servicos;