
function NossaHistoria() {
    return (
        <div className="container">
            <div id="nossa-historia">
                <div id="linha-vertical-nossa-historia"></div>
                <div id="area-textos-nossa-historia">
                    <p className="titulos">Nossa História</p>
                    <p className="textos">
                        A Yed foi forjada no profundo desejo de transformar sonhos em realidade, visando 
                        ampliação do uso da tecnologia para alavancar o crescimento socioeconômico regional 
                        e nacional. Sediada na histórica São João del-Rei-MG, trazemos em nossa essência 
                        a inquietude dos inconfidentes, onde prezamos sempre pela liberdade, inovação 
                        e equidade.
                        <br/>
                        <br/>
                        Para nós a tecnologia é uma ferramenta de integração e facilitação da interação 
                        social, além de reduzir gastos, economizar recursos e melhorar os resultados de 
                        processos. Desta forma, nossos projetos visam a facilidade, usabilidade e 
                        integração, sempre com uma atenção especial nos impactos ambientais que nossas 
                        soluções possam causar.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NossaHistoria;