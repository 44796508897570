import { ReactComponent as IconeYoutube } from '../assets/icone_youtube.svg';
import { ReactComponent as IconeInstagram } from '../assets/icone_instagram.svg';
import { ReactComponent as IconeLinkedin } from '../assets/icone_linkedin.svg';
import "./rodape.css"

function Rodape() {

    const ano = new Date().getFullYear();
   
    return (
        <div>
            <div id="rodape">
                <div id="conteudo-rodape">
                    <div id="area-icones">
                        <a className="area-icone" href="https://www.youtube.com/channel/UClTQrsg-tqXVd1HrVlGmPdA/" target="_blank">
                            <IconeYoutube className='icone' />
                        </a>
                        <a className="area-icone" href="https://www.instagram.com/yedtech/" target="_blank">
                            <IconeInstagram className='icone' />
                        </a>
                        <a className="area-icone" href="https://www.linkedin.com/company/yedtecnologias/" target="_blank">
                            <IconeLinkedin className='icone' />
                        </a>
                    </div>
                    <div id="area-textos-rodape">
                        <p>CNPJ: 35.471.610/0001-58</p>
                        <p>contato@yed.com.br</p>
                        <a href='https://www.linkedin.com/company/yedtecnologias/' target="_blank">Trabalhe conosco</a>
                    </div>
                </div>
                {/* <br/> */}
                <p id="direitos">Todos os direitos reservados à Yed Tecnologias {ano} &copy;</p>
            </div>
        </div>

    );
}

export default Rodape;