import React, { useState } from "react";
import Cabecalho from "./cabecalho/cabecalho";
import OQueFazemos from "./oquefazemos/oquefazemos";
import NossaHistoria from "./nossahistoria/nossahistoria";
import Servicos from "./servicos/servicos";
import { ReactComponent as ArteLinhaCurva } from './assets/linhacurva.svg';
import Rodape from "./rodape/rodape";
import "./index.css";
import "./cabecalho/cabecalho.css";
import "./oquefazemos/oquefazemos.css";
import "./nossahistoria/nossahistoria.css";
import "./servicos/servicos.css";



function App() {

  const [dimensoes, setDimensoes] = useState({
    altura: window.innerHeight,
    largura: window.innerWidth
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensoes({
        altura: window.innerHeight,
        largura: window.innerWidth
      })
      AtualizaCSS();
    }
    function AtualizaCSS() {
      if (window.innerWidth >= 640) {
        document.getElementById("cabecalho").style.transition = "1s";
        document.getElementById("cabecalho").style.borderRadius = "0 45px 45px 0";
      }
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const LinhaCurva = () => {
    return (
      <div id="area-linha-curva">
        <ArteLinhaCurva id="linha-curva" />
      </div>
    );
  }

  return (
    <div id="fullpage">
      <Cabecalho dimensoes={dimensoes} />
      <OQueFazemos />
      <NossaHistoria />
      <Servicos />
      <LinhaCurva />
      <Rodape />
    </div>
  );
}

export default App;
