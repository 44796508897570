
function OQueFazemos() {
    return (
        <div className="container">
            <div id="o-que-fazemos">
                <div id="linha-vertical-o-que-fazemos"></div>
                <div id="area-textos-o-que-fazemos">
                    <p className="titulos">O que Fazemos</p>
                    <p className="textos">
                        A Yed Tecnologias é uma empresa voltada para o desenvolvimento de tecnologias e
                        consultoria. Desenvolvemos soluções web e mobile multiplataforma, e prestamos
                        consultoria em desenvolvimento e ServiceNow.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default OQueFazemos;